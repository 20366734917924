<template>
  <div class="page-web page_regi">
 <RegiHead :MyLocal="location" @newLocal="location = $event" />
    <div class="regi-bd">
      <div class="regi_name">{{ $t('account') }}</div>
          <el-form ref="form2" :rules="rules2" class="regi-from sign_form" :model="form2">
            <el-form-item prop="email">
              <div class="regi_group">
                <div class="regi_gr_t">{{ $t('account0') }}</div>
                <div class="regi_gr_b">
                  <el-input v-model="form2.email" placeholder=""></el-input>
                </div>
              </div>
            </el-form-item>
            <el-form-item prop="pass">
              <div class="regi_group">
                <div class="regi_gr_t">{{ $t('password2') }}</div>
                <div class="regi_gr_b">
                  <el-input v-model="form2.pass" placeholder="" :type="pass2 ? 'password' : 'text'"> </el-input>
                  <div class="regi_eye" @click="eye2">
                    <i class="iconfont icon-eye-close" v-if="Eye2"></i>
                    <i class="iconfont icon-eye" v-else></i>
                  </div>
                </div>
              </div>
            </el-form-item>
            <el-button class="btn" :plain="true" @click="submitForm2()">{{ $t('sign') }}</el-button>
          </el-form>

      <div class="login_tips">
         <el-button type="text" @click="dialogFormVisible = true">{{ $t('password3') }}</el-button> 
          
        <router-link to="/register" style="color: #f0b90b">{{ $t('account2') }}</router-link>
      </div>
      <el-dialog  :visible.sync="dialogFormVisible" width="25%" >
        <h1 style="color: #f0b90b; font-size:20px">{{$t('resetpassword')}}</h1>
        <el-input v-model="form1.userId" style="width:100% ; padding: 5px;" :placeholder="$t('logErr')"></el-input>
        <el-input v-model="form1.captcha" style="width:70%; padding: 5px;" :placeholder="$t('code3')"></el-input>
        <el-button style="width:28.5% ; height:40px;padding: 0px;" @click="getresetcaptcha()">{{$t('code2')}}</el-button>
        <el-input v-model="form1.password" style="width:100%; padding: 5px;" show-password :placeholder="$t('logErr2')"></el-input>
        <!-- <el-input v-model="form1.captcha" style="width:100%;" placeholder=""></el-input>   -->
         <el-button style="width:98% ; height:40px; margin: 5px auto; " @click="getresetpassword()">{{$t('reset')}}</el-button>
      </el-dialog>
      <div class="regi_tips">Bnbncoin ©2024</div>
    </div>
  </div>
  
</template>
<script>
import { login, resetpassword } from '@/api/virtual/register'
import { getresetcaptcha, getresetpassword } from '@/api/virtual/virtual'
import RegiHead from '@/components/RegiHead.vue'
export default {
  components: {
    RegiHead,
  },
  data() {
    
    return {
      activeName: 'second',
      dialogFormVisible: false,
      disabled2: true,
       email:'',
      rules: {
        phone: [
          {
            required: true,
            message: this.$t('logErr'),
            trigger: 'blur',
          },
        ],
        pass: [
          {
            required: true,
            message: this.$t('logErr2'),
            trigger: 'blur',
          },
          {
            type: 'string',
            min: 6,
            message: this.$t('logErr3'),
            trigger: 'blur',
          },
        ],
      },
      form2: {
        email: '',
        pass: '',
      },
      form1: {
        UserId: '',
        captcha: '',
        password: '',
        pass1: '',
      },
      rules2: {
        email: [
          {
            required: true,
            message: this.$t('logErr'),
            trigger: 'blur',
          },
        ],
        pass: [
          {
            required: true,
            message: this.$t('logErr2'),
            trigger: 'blur',
          },
          {
            type: 'string',
            min: 6,
            message: this.$t('logErr3'),
            trigger: 'blur',
          },
        ],
      },
      Eye: true,
      Eye2: true,
      pass: true,
      pass2: true,
      location: 'en_US',
      country: [],
      countryImageUrl: '',
    }
  },
  created() {
    this.location = localStorage.getItem('lang')
  },
  mounted() {
    this.countryList()
  },
  methods: {
    open() {
        this.$prompt(this.$t('logErr'), this.$t('resetpassword'), {
          confirmButtonText: this.$t('reset'),
          cancelButtonText: this.$t('meun13_4'),
        }).then(({ value }) => {
          this.email= value;
          this.resetpassword();
        }).catch(() => {
          this.$message({
            type: 'info',
            message: this.$t('meun13_7')
          });       
        });
      },
      resetpassword()
     {
        resetpassword(this.email).then(res=>{
              if(res.code==200)
              {
                this.$message({
                type: 'success',
                message: res.msg
                });
              }
              else{
                this.$message.error(res.msg);
              }
          }) 
     } ,
    getresetcaptcha(){
        getresetcaptcha(this.form1.userId).then(res=>{
          if(res.code==200)
              {
                this.$message({
                type: 'success',
                message: res.msg
                });
              }
              else{
                this.$message.error(res.msg);
              }
        })
    },
    getresetpassword(){
        getresetpassword(this.form1).then(res=>{
            if(res.code==200)
              {
                this.$message({
                type: 'success',
                message: res.msg
                });
              }
              else{
                this.$message.error(res.msg);
              }   
        })
        this.dialogFormVisible = false;
        this.form1.captcha = undefined
        this.form1.UserId = undefined
        this.form1.password= undefined
    },
    countryItem(url) {
      this.countryImageUrl = url
    },
    submitForm2() {
       login(this.form2).then(response=>{
        console.log(response);
        if(response.code==200)
        {
          localStorage.setItem('token', response.data.token); // 存储令牌
          localStorage.setItem('invite', response.data.invite);
          localStorage.setItem('userId', response.data.userId);
          localStorage.setItem('userName', response.data.userName);
          this.$store.commit('SET_ISLOGIN', true);
          this.$message({
          message: response.msg,
          type: 'success'
          });
          this.$router.push('/')
        }
        else{
          this.$message.error(response.msg);
        }
      })
    },
    handleClick(tab, event) {
      console.log(tab, event)
    },
    eye() {
      this.Eye = !this.Eye
      this.pass = !this.pass
    },
    eye2() {
      this.Eye2 = !this.Eye2
      this.pass2 = !this.pass2
    },
  },
}
</script>
<style scoped>
</style>
